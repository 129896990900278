import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import "./CovidAttest.css";
import Sign from '../assets/imgs/sign.png';

export class CovidAttest extends Component {

    constructor(props) {
        super(props);

        this.state = {
            user: JSON.parse(localStorage.getItem('user')),
            isLogged: this.props.isLogged,
        }
    }

    renderDate(date) {
        return date.getDate().toString().padStart(2, '0') + '-' + (date.getMonth() + 1).toString().padStart(2, '0') + '-' + date.getFullYear();
    }

    render() {

        const { user, isLogged } = this.state;
        const { date } = this.props;

        let today = this.renderDate(date);
        

        if (!isLogged)
            return <Redirect to="/" push />

        return (
            <div className="covidAttest">
                <div className="covidAttest__header">
                    <p>
                        <span>{user.team.name}</span><br></br>
                        <span>{ user.team.administrator}, Administrateur</span><br></br>
                        <span>GSM: {user.team.gsm}</span><br></br>
                        <span>{user.team.adress.street}, {user.team.adress.streetNumber} {user.team.adress.postBox !== null ? " Boite " + user.team.adress.postBox : ""}</span><br></br>
                        <span>{user.team.adress.postalCode} {user.team.adress.city}</span><br></br>
                        <span>BCE: { user.team.bce}</span><br></br>
                        <span>ONSS: { user.team.onss}</span>
                    </p>
                    <div className="d-flex justify-content-end">
                        <p>
                            <span>{user.firstName + " " + user.lastName}</span><br></br>
                            <span>{user.adress.street + " n°" + user.adress.streetNumber}{user.adress.postBox !== null ? " Boite " + user.adress.postBox : ""}</span><br></br>
                            <span>{user.adress.postalCode + " " + user.adress.city}</span><br></br>
                        </p>
                    </div>
                </div>
                <div className="d-flex justify-content-end">
                    <p>Tournai, le {today}</p>
                </div>
                <p>Concerne: Attestation de présence </p>
                <br></br>
                <p>Madame, Monsieur</p>
                <br></br>
                <p>Je soussigné, Frédéric FOUCART, Hygiéniste et Administrateur de la SRL, confirme que la présence de la personne susmentionnée est obligatoire pour le bon fonctionnement de notre entreprise. </p>
                <br></br>
                <p>D'avance je vous remercie d'accepter sa présence sur son lieu de travail.</p>
                <br></br>
                <img src={Sign} width={200} />
                <p>Frédéric FOUCART, Administrateur</p>
                <br></br>
                <br></br>
                <div className="d-flex flex-column covidAttest__footer">
                    <div>Tél: +32(0)69/57.57.62</div>
                    <div>{user.team.name}</div>
                    <div>www.hygie.care</div>
                </div>
            </div>
        );
    }
}

CovidAttest.defaultProps = {
    date: new Date()
};