import React, { Component } from 'react';
import './Login.css';

export class Login extends Component {

    constructor(props) {
        super(props);

        this.state = {
            login: '',
            password: '',
            error : ''
        }
    }

    login() {
        (async () => {
            const rawResponse = await fetch('/Login', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    Login: this.state.login,
                    Password: this.state.password
                })
            });

            if (rawResponse.ok) {
                const content = await rawResponse.json();
                localStorage.setItem('user', JSON.stringify(content));
                localStorage.setItem('isLogged', 'true');
                this.props.onLogIn();
            }
            else {
                this.setState({
                    login: '',
                    password: '',
                    error:"Le nom d'utilisateur ou le mot de passe est incorrect !"
                });
            }
        })();
    }

    handleLoginChange(e) {
        this.setState({ login: e.target.value });

    }

    handlePasswordChange(e) {
        this.setState({ password: e.target.value });
    }

    render() {

        const { error, login, password } = this.state;

        return (
            <form className="row">
                <div className="col-lg-6">
                    <p>Veuillez vous authentifier pour pouvoir consulter votre attestation:</p>

                    <div className="form-group">
                        <label htmlFor="inputEmail">Utilisateur</label>
                        <input type="text" className="form-control" id="inputEmail" placeholder="Utilisateur" value={login} onChange={(e) => this.handleLoginChange(e)} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="InputPassword">Mot de passe</label>
                        <input type="password" className="form-control" id="inputPassword" placeholder="Mot de passe" value={password} onChange={(e) => this.handlePasswordChange(e)} />
                    </div>
                    <div className="d-flex justify-content-between">
                        <strong className="login__error-message">{error}</strong>
                        <button type="button" className="h-primary-button" onClick={() => this.login()}>Connexion</button>
                    </div>
                </div>
                
            </form>
        );
    }
}
