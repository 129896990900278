import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';

export class Layout extends Component {

    render() {
        return (
            <div>
                <NavMenu isLogged={this.props.isLogged} onLogOff={() => this.props.onLogOff()} />
                <Container className="mt-5">
                    {this.props.children}
                </Container>
            </div>
        );
    }
}
