import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { CovidAttest } from './components/CovidAttest';

import './App.css'

export default class App extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLogged: JSON.parse(localStorage.getItem('isLogged')),
        };

        this.date = new Date();
    }

    logOff() {
        this.setState({ isLogged: false });
    }

    logIn() {
        this.setState({ isLogged: true });
    }

    dateChanged(newDate) {
        this.date = newDate;
        console.log(this.date);
    }

    render() {

        const { isLogged, date } = this.state;

        return (
            <Layout isLogged={isLogged} onLogOff={() => this.logOff()}>
                <Route path='/attest' component={() => <CovidAttest isLogged={isLogged} date={this.date} />} />
                <Route exact path='/' component={() => <Home isLogged={isLogged} onLogIn={() => this.logIn()} onDateChanged={(newDate) => this.dateChanged(newDate)} />} />
            </Layout>
        );
    }
}
