import React, { Component } from 'react';
import { Login } from './Login';
import { Redirect } from 'react-router-dom';

export class Home extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLogged: this.props.isLogged,
            redirectToAttest: false,
            date: new Date()
        }
    }

    onLogIn() {
        this.setState({ isLogged: true });
        this.props.onLogIn();
    }

    redirect() {
        this.props.onDateChanged(new Date());
        this.setState({ redirectToAttest: true });
    }


    redirectWithDate() {
        this.props.onDateChanged(this.state.date);
        this.setState({ redirectToAttest: true });
    }

    handleDateChange(e) {
        this.setState({ date: new Date(e.target.value) });
    }

    renderDateToInput(date) {
        return date.getFullYear() + '-' + (date.getMonth() + 1).toString().padStart(2, '0') + '-' + date.getDate().toString().padStart(2, '0');
    }

    render() {

        const { isLogged, redirectToAttest } = this.state;

        if (redirectToAttest && isLogged) {
            return <Redirect to="/attest" push />
        }
        else {

            const user = JSON.parse(localStorage.getItem('user'));
            const today = new Date();
            const { date } = this.state;

            return (
                <div>
                    {!isLogged &&
                        <>
                            <h1 className="title mb-5">Attestations COVID</h1>
                            <p>Sur cette page, vous trouverez votre attestation COVID du jour.</p>
                            <Login onLogIn={() => this.onLogIn()} />
                        </>
                    }

                    {isLogged &&
                        <>
                            <div className="row">
                                <div className="col-lg-6">
                                    <h1 className="title mb-5">Bonjour {user.firstName}</h1>
                                    <p>Sur cette page, tu trouveras ton attestation COVID du jour.</p>
                                    <div className="form-group">
                                        <input className="form-control" type="date" min={this.renderDateToInput(new Date(2020, 1, 1))} max={this.renderDateToInput(today)} value={this.renderDateToInput(date)} onChange={(e) => this.handleDateChange(e)} />
                                    </div>
                                    <div className="d-flex justify-content-end">
                                        <button type="button" className="h-primary-button" onClick={() => this.redirectWithDate()}>Mon attestation</button>
                                    </div>
                                </div>

                            </div>

                        </>
                    }
                </div>
            );
        }
    }
}
